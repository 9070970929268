<!--<div class="block-slideshow block block-slideshow&#45;&#45;layout&#45;&#45;full mb-0">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12">-->
<!--                <div class="block-slideshow__body">-->
<!--                    <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>-->
<!--                        <ng-template *ngFor="let slide of slides" carouselSlide>-->
<!--                            <a class="block-slideshow__slide block-slideshow__slide-image&#45;&#45;desktop" href="{{slide.url}}" target="{{slide.type === 'Exterior' ? '_blank' : ''}}" appClick>-->
<!--                                <div class="block-slideshow__slide-image block-slideshow__slide-image&#45;&#45;desktop block-slideshow&#45;&#45;layout&#45;&#45;full" [ngStyle]="{'background-image': 'url('+(slide.image_full)+')'}"></div>-->
<!--                            </a>-->
<!--                        </ng-template>-->
<!--                    </owl-carousel-o>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="mb-0 block-slideshow block block-slideshow--layout--full" *ngIf="slides.length">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="block-slideshow__body">
                    <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>
                        <ng-template *ngFor="let slide of slides" carouselSlide>
                            <a class="block-slideshow__slide" href="{{slide.url}}" target="{{slide.type === 'Exterior' ? '_blank' : ''}}" appClick>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop" [ngStyle]="{'background-image': 'url('+(withDepartments ? slide.image_classic : slide.image_full)+')'}"></div>
                            </a>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>

<app-page-header [header]="title" [breadcrumbs]="[]"></app-page-header>
<!-- <app-page-header [header]="'Mi Carrito'" [breadcrumbs]="[
    {label: 'Mi Carrito', url: ''},
    {label: 'Dirección de Envío', url: ''},
    {label: 'Facturación', url: ''},
    {label: 'Pago', url: ''}
]"></app-page-header> -->

<div class="block block-empty" *ngIf="!items.length">
    <div class="container">
        <div class="block-empty__body">
            <div class="block-empty__message">Su cesta está vacía!</div>
            <div class="block-empty__actions">
                <a [routerLink]="root.shop()" class="btn btn-primary btn-sm">Continuar</a>
            </div>
        </div>
    </div>
</div>
<!-- <div id="stepper1" class="bs-stepper" [ngClass]="{'vertical':innerWidth <= 600}"> -->
<div id="stepper1" class="bs-stepper" role="tablist">
    <div class="container">
        <div style="text-align: center;">
            <div class="bs-stepper-header tab-resp">
                <div class="step" data-target="#test-l-1">
                    <button (click)="changeTitle('Mi Carrito')" role="tab" class="step-trigger">
                        <!-- <div class="bs-stepper-circle-1"></div> -->
                        <span class="breadcrumb-item item-bread">
                            Mi carrito
                            <app-icon class="breadcrumb-arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
                        </span>
                    </button>
                </div>
                <div class="step" data-target="#test-l-2">
                    <button (click)="changeTitle('Dirección de Envío')" role="tab" class="step-trigger">
                        <!-- <span class="bs-stepper-circle-2"></span> -->
                        <span class="breadcrumb-item item-bread">
                            Dirección de envio
                            <app-icon class="breadcrumb-arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
                        </span>
                    </button>
                </div>
                <div class="step" data-target="#test-l-3">
                    <button (click)="changeTitle('Facturación')" role="tab" class="step-trigger">
                        <!-- <span class="bs-stepper-circle-3"></span> -->
                        <div class="breadcrumb-item item-bread">
                            Facturación
                            <app-icon class="breadcrumb-arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
                        </div>
                    </button>
                </div>
                <div class="step" data-target="#test-l-4">
                    <button (click)="changeTitle('Pago')" role="tab" class="step-trigger">
                        <!-- <span class="bs-stepper-circle-4"></span> -->
                        <span class="breadcrumb-item item-bread">
                            Pago
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="cart block bs-stepper-content" *ngIf="items.length">
        <div id="test-l-1" role="tabpanel" class="content bs-stepper-pane">
            <div class="row justify-content-end pt-5">
                <div class="col-12 col-md-12 col-lg-6 col-xl-8 table-responsive">
                    <table class="cart__table">
                        <thead class="cart-table__head" style="border-bottom: 2px solid #28a745 !important; border-collapse: collapse !important; background-color: #fff;">
                            <tr class="cart-table__row">
                                <th class="cart-table__column cart-table__column--image">Imagen</th>
                                <!-- <th class="cart-table__column cart-table__column--product">Código</th> -->
                                <th class="cart-table__column cart-table__column--product">Laboratorio</th>
                                <th class="cart-table__column cart-table__column--product">Producto</th>
                                <th class="cart-table__column cart-table__column--price">Precio</th>
                                <th class="cart-table__column cart-table__column--quantity">Cantidad</th>
                                <th class="cart-table__column cart-table__column--total">Total</th>
                                <th class="cart-table__column cart-table__column--remove"></th>
                            </tr>
                        </thead>
                        <tbody class="cart-table__body">
                            <tr *ngFor="let item of items" class="cart-table__row">
                                <td class="cart-table__column cart-table__column--image">
                                    <div *ngIf="item.cartItem.product.coverImage" class="product-image">
                                        <a [routerLink]="root.product(item.cartItem.product)" class="product-image__body">
                                            <div class="product-card__badges-list" *ngIf="item.cartItem.product.discount != ''">
                                                <div class="product-card__badge product-card__badge--sale">Oferta -{{ item.cartItem.product.discount|number:'1.2-2' }}%</div>
                                                <!-- <div *ngIf="product.badges.includes('hot')" class="product-card__badge product-card__badge--hot">Hot</div>
                                                <div *ngIf="product.badges.includes('new')" class="product-card__badge product-card__badge--new">New</div> -->
                                            </div>
                                            <img  class="product-image__img" [src]="getImage(item.cartItem.product)" width="80px" alt="">
                                            <!-- <img *ngIf="item.cartItem.product.coverImage === 'no-photo.png'" class="product-image__img" [src]="URL_SERVICES_IMG + '/' + '/' +item.cartItem.product.coverImage" alt=""> -->
                                        </a>
                                    </div>
                                </td>
                                <!-- <td class="cart-table__column cart-table__column--total text-left" data-title="Código">{{ item.cartItem.product.sku }}</td> -->
                                <td class="cart-table__column cart-table__column--total text-left" data-title="Laboratorio">{{ item.cartItem.product.department }}</td>
                                <td class="cart-table__column cart-table__column--product">
                                    <a [routerLink]="root.product(item.cartItem.product)" class="cart-table__product-name">{{ item.cartItem.product.name }}</a>
                                    <div id="nav-cod">
                                        <ul>
                                            <li class="font-weight-bold">Código: {{ item.cartItem.product.sku }}</li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="cart-table__column cart-table__column--price" data-title="Price">
                                    <div *ngIf="item.quantity >= item.cartItem.product.pieces_offer">
                                        {{ (item.cartItem.product.priceProduct || item.cartItem.product.price)|currencyFormat }}
                                    </div>
                                    <div *ngIf="!(item.quantity >= item.cartItem.product.pieces_offer)">
                                        {{ (item.cartItem.product.priceProduct || item.cartItem.product.price) + ((item.cartItem.product.priceProduct || item.cartItem.product.price) * item.cartItem.product.iva)|currencyFormat }}
                                    </div>
                                </td>
                                <td class="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                    <app-input-number [formControl]="item.quantityControl" [min]="1" (keyup)="update($event)" (click)="update()"></app-input-number>
                                </td>
                                <td class="cart-table__column cart-table__column--total" data-title="Total">
                                    <div *ngIf="item.quantity >= item.cartItem.product.pieces_offer">
                                        {{ (item.cartItem.product.priceProduct || item.cartItem.product.price) * item.quantity|currencyFormat }} MXN
                                    </div>
                                    <div *ngIf="!(item.quantity >= item.cartItem.product.pieces_offer)">
                                        {{ ((item.cartItem.product.priceProduct || item.cartItem.product.price) + ((item.cartItem.product.priceProduct || item.cartItem.product.price) * item.cartItem.product.iva)) * item.quantity|currencyFormat }} MXN
                                    </div>
                                </td>
                                <td class="cart-table__column cart-table__column--remove">
                                    <button type="button" class="btn btn-light btn-sm btn-svg-icon" (click)="remove(item.cartItem)" [ngClass]="{'btn-loading': removedItems.includes(item.cartItem)}">
                                            <app-icon name="cross-12" size="12"></app-icon>
                                        </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="cart__actions">
                        <!-- <form class="cart__coupon-form">
                            <label for="input-coupon-code" class="sr-only">Contraseña</label>
                            <input type="text" class="form-control" id="input-coupon-code" placeholder="Cupon">
                            <button type="submit" class="btn btn-primary">Aplicar Cupon</button>
                        </form> -->
                        <div class="cart__buttons">
                            <!-- <div class="col-12 col-md-6 col-lg-6 col-xl-7 mt-3"> -->
                            <!-- <span class="font-weight-bold mb-3">Observación</span> -->
                            <textarea class="form-control" [(ngModel)]="comment" (focusout)="saveComment($event)" name="comments" placeholder="Agrega alguna observación o comentario adicional" rows="3"></textarea>
                            <!-- </div>
                            <a routerLink="/" class="btn btn-light">Continuar Comprando</a>
                            <button type="button" class="btn btn-primary cart__update-button" (click)="update()" [ngClass]="{'btn-loading': updating}" [disabled]="!needUpdate()">Actualizar Carro</button> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-xl-4 margin-resp">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title cart-title">Total A Pagar</h3>

                            <table class="cart__totals">
                                <thead *ngIf="(cart.totals$|async).length" class="cart__totals-header">
                                    <tr>
                                        <th>Subtotal</th>
                                        <td>{{ cart.subtotal$|async|currencyFormat }} MXN</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="(cart.totals$|async).length" class="cart__totals-body">
                                    <tr *ngFor="let total of cart.totals$|async">
                                        <th>{{ total.title }}</th>
                                        <td>
                                            {{ total.price|currencyFormat }} MXN
                                            <div *ngIf="total.type === 'shipping'" class="cart__calc-shipping"><a href="" (click)="$event.preventDefault()">Calcular envio</a></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="cart__totals-footer">
                                    <tr>
                                        <th>Total</th>
                                        <td>{{ cart.total$|async|currencyFormat }} MXN</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <a class="btn btn-success btn-xl btn-block cart__checkout-button" style="color:#fff !important" (click)="next({from:'cart'})">Finalizar Pedido</a>
                            <a class="btn btn-primary btn-xl btn-block cart__checkout-button" routerLink="./">Seguir Comprando</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="test-l-2" role="tabpanel" class="content bs-stepper-pane mt-5">
            <app-checkout (nextstep)="next($event)"></app-checkout>
        </div>
        <div id="test-l-3" role="tabpanel" class="content bs-stepper-pane mt-5">
            <div class="container">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">AGREGAR DATOS DE FACTURACIÓN</h3>
                        <div class="payment-methods">
                            <!-- <ul class="payment-methods__list" *ngIf="billings">
                                <li class="payment-methods__item" *ngFor="let item of billings">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" id="item.id" (change)="billingAddress(item)" name="checkout_payment_method" [checked]="item.checked" type="radio">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                            <span class="payment-methods__item-title">{{item.razon}}, {{item.address}}, {{item.rfc}}</span>
                                            </label>
                                </li>
                            </ul> -->
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="checkout-last-name">Razón Social</label>
                                    <input type="text" class="form-control" id="checkout-razon" name="razon" [formControl]="checkoutFormBilling.controls['razon']" readonly [(ngModel)]="_companyService.user.name_company" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.razon.touched && checkoutFormBilling.controls.razon.errors?.required" class="text text-danger">
                                        Razón Social es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="field-label">Tipo</label>
                                    <input type="text" class="form-control" id="checkout-type" name="type" [formControl]="checkoutFormBilling.controls['type']" readonly [(ngModel)]="_companyService.user.type" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.type.touched && checkoutFormBilling.controls.type.errors?.required" class="text text-danger">
                                        Tipo es requerido
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-last-name">RFC</label>
                                    <input type="text" class="form-control" id="checkout-rfc" name="rfc" [formControl]="checkoutFormBilling.controls['rfc']" readonly [(ngModel)]="_companyService.user.rfc" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.rfc.touched && checkoutFormBilling.controls.rfc.errors?.required" class="text text-danger">
                                        RFC es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-last-name">Calle</label>
                                    <input type="text" class="form-control" id="checkout-last-name" name="street" [formControl]="checkoutFormBilling.controls['street']" [(ngModel)]="_companyService.user.street" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.street.touched && checkoutFormBilling.controls.street.errors?.required" class="text text-danger">
                                        Calle es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="checkout-postcode">Colonia</label>
                                    <input type="text" class="form-control" name="colony" [formControl]="checkoutFormBilling.controls['colony']" [(ngModel)]="_companyService.user.colony" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.colony.touched && checkoutFormBilling.controls.colony.errors?.required" class="text text-danger">
                                        Colonia es requerida
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="checkout-company-name">No. Exterior</label>
                                    <input type="text" class="form-control" name="exterior" [formControl]="checkoutFormBilling.controls['exterior']" [(ngModel)]="_companyService.user.nro_ext" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.exterior.touched && checkoutFormBilling.controls.exterior.errors?.required" class="text text-danger">
                                        No Exterior es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="checkout-company-name">No. Interior</label>
                                    <input type="text" class="form-control" name="interior" [formControl]="checkoutFormBilling.controls['interior']" [(ngModel)]="_companyService.user.nro_int" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.interior.touched && checkoutFormBilling.controls.interior.errors?.required" class="text text-danger">
                                        No Interior es requerido.
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="checkout-postcode">Cod. Postal</label>
                                    <input type="text" class="form-control" name="postal" [formControl]="checkoutFormBilling.controls['postal']" [(ngModel)]="_companyService.user.postal" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.postal.touched && checkoutFormBilling.controls.postal.errors?.required" class="text text-danger">
                                        Código postal es requerido
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="checkout-postcode">Ciudad</label>
                                    <input type="text" class="form-control" name="city" [formControl]="checkoutFormBilling.controls['city']" [(ngModel)]="_companyService.user.city" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.city.touched && checkoutFormBilling.controls.city.errors?.required" class="text text-danger">
                                        Ciudad es requerido
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="checkout-postcode">Ciudad</label>
                                    <input type="text" class="form-control" name="state" [formControl]="checkoutFormBilling.controls['state']" [(ngModel)]="_companyService.user.state" readonly placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.state.touched && checkoutFormBilling.controls.state.errors?.required" class="text text-danger">
                                        Estado es requerido
                                    </div>
                                </div>
                                <!-- <div class="form-group col-md-3">
                                    <label for="checkout-country">País</label>
                                    <input type="text" class="form-control" name="country" [formControl]="checkoutFormBilling.controls['country']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutFormBilling.controls.country.touched && checkoutFormBilling.controls.country.errors?.required" class="text text-danger">
                                        País postal es requerido
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-md-12 mt-2 mb-3 text-left pull-left">
                            <input type="checkbox" name="ticket" [formControl]="checkoutFormBilling.controls['ticket']" id="ticket">
                            <label for="ticket" class="font-weight-bold"> &nbsp; Solicito Factura Electrónica</label>
                        </div>
                        <div class="col-md-12 mt-2 mb-3 text-right pull-right">
                            <span class="font-weight-bold text-primary float-left pt-3"><a (click)="addNewAddress(addressBillingRef)" class="text-primary pointer">Solicitud de cambio de facturación</a> </span>
                            <button *ngIf="billings.length === 0" class="btn btn-info pull-right btn-lg" (click)="openModalNewBilling()">Agregar Datos de Factura</button>
                            <button (click)="next({from:'bill', billingAddress: checkoutFormBilling.value})" class="btn btn-next ml-3 btn-lg">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="test-l-4" role="tabpanel" class="content bs-stepper-pane mt-5">
            <div class="container">
                <div class="payment-methods">
                    <ul class="payment-methods__list">

                        <li class="payment-methods__item payment-methods__item--active" *ngFor="let item of paymentMethods">
                            <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" [checked]="item.id == 1" type="radio" (change)="changeTypePayment(item)">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">{{item.name}} {{ item.id == 7 ? '($' + _companyService.user.credit + ' MXN)' : ''}}</span>
                                    </label>
                        </li>
                    </ul>
                </div>
                <button [disabled]="disabledButtonFinish" (click)="createOrder()" class="btn btn-primary float-right">Finalizar Compra</button>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="col-12 labs-carousel" *ngIf="this.products.length">
        <div>
            <h6 class="prominent" style="text-align: center; color: #1a66ff">¿Has Olvidado Algo?</h6>
        </div>
        <div class="block-products-carousel" [ngClass]="{'block-products-carousel--has-items': !!products?.length}" [attr.data-layout]="'grid'">
            <div [ngClass]="{'container': true}">
                <div class="block-products-carousel__slider">
                    <div class="block-products-carousel__preloader"></div>
                    <owl-carousel-o [options]="customOptions" appOwlPreventClick>
                        <ng-template *ngFor="let product of products" carouselSlide>
                            <div class="block-products-carousel__column">
                                <div class="block-products-carousel__cell">
                                    <app-product-prominent [product]="product"></app-product-prominent>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #addressBillingRef let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-blue">Solicitar cambio de Dirección</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="card mb-0 contact-us">
                <!-- <div class="contact-us__map">
                    <iframe src='https://maps.google.com/maps?q=Holbrook-Palmer%20Park&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed' frameborder='0' scrolling='no' marginheight='0' marginwidth='0'></iframe>
                </div> -->
                <div class="card-body">
                    <div class="contact-us__container">
                        <div class="row">
                            <div class="col-12 col-lg-12 col-xl-12">
                                <div class="card mb-lg-0">
                                    <div class="card-body">
                                        <div class="row mb-3">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="checkout-last-name">Razón Social</label>
                                                    <input type="text" class="form-control" id="checkout-razon" name="razon" [formControl]="addressBillingForm.controls['razon']" value="" placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.razon.touched && addressBillingForm.controls.razon.errors?.required" class="text text-danger">
                                                        Razón Social es requerido.
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label class="field-label">Tipo</label>
                                                    <select [(ngModel)]="type" class="form-control" name="type" [formControl]="addressBillingForm.controls['type']">
                                                        <option value="Persona Fisica" selected>Persona Física</option>
                                                        <option value="Persona Moral">Persona Moral</option>
                                                    </select>
                                                    <div *ngIf="addressBillingForm.controls.type.touched && addressBillingForm.controls.type.errors?.required" class="text text-danger">
                                                        Tipo es requerido
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="checkout-last-name">RFC</label>
                                                    <input type="text" class="form-control" id="checkout-rfc" name="rfc" [formControl]="addressBillingForm.controls['rfc']" value="" placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.rfc.touched && addressBillingForm.controls.rfc.errors?.required" class="text text-danger">
                                                        RFC es requerido.
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="checkout-last-name">Calle</label>
                                                    <input type="text" class="form-control" id="checkout-last-name" name="street" [formControl]="addressBillingForm.controls['street']" [(ngModel)]="_companyService.user.street" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.street.touched && addressBillingForm.controls.street.errors?.required" class="text text-danger">
                                                        Calle es requerido.
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="checkout-postcode">Colonia</label>
                                                    <input type="text" class="form-control" name="colony" [formControl]="addressBillingForm.controls['colony']" [(ngModel)]="_companyService.user.colony" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.colony.touched && addressBillingForm.controls.colony.errors?.required" class="text text-danger">
                                                        Colonia es requerida
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="checkout-company-name">No. Exterior</label>
                                                    <input type="text" class="form-control" name="exterior" [formControl]="addressBillingForm.controls['exterior']" [(ngModel)]="_companyService.user.nro_ext" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.exterior.touched && addressBillingForm.controls.exterior.errors?.required" class="text text-danger">
                                                        No Exterior es requerido.
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label for="checkout-company-name">No. Interior</label>
                                                    <input type="text" class="form-control" name="interior" [formControl]="addressBillingForm.controls['interior']" [(ngModel)]="_companyService.user.nro_int" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.interior.touched && addressBillingForm.controls.interior.errors?.required" class="text text-danger">
                                                        No Interior es requerido.
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label for="checkout-postcode">Cod. Postal</label>
                                                    <input type="text" class="form-control" name="postal" [formControl]="addressBillingForm.controls['postal']" [(ngModel)]="_companyService.user.postal" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.postal.touched && addressBillingForm.controls.postal.errors?.required" class="text text-danger">
                                                        Código postal es requerido
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label for="checkout-postcode">Ciudad</label>
                                                    <input type="text" class="form-control" name="city" [formControl]="addressBillingForm.controls['city']" [(ngModel)]="_companyService.user.city" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.city.touched && addressBillingForm.controls.city.errors?.required" class="text text-danger">
                                                        Ciudad es requerido
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label for="checkout-postcode">Ciudad</label>
                                                    <input type="text" class="form-control" name="state" [formControl]="addressBillingForm.controls['state']" [(ngModel)]="_companyService.user.state" readonly placeholder="" autocomplete="off">
                                                    <div *ngIf="addressBillingForm.controls.state.touched && addressBillingForm.controls.state.errors?.required" class="text text-danger">
                                                        Estado es requerido
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-divider"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="sendAddress()">GUARDAR</button>
    </div>
</ng-template>
